import ca from './ca.json'
import en from './en.json'
import eu from './eu.json'
import enUS from './en-US.json'
import fr from './fr.json'
import ptBR from './pt-BR.json'
import pl from './pl.json'

export const messages = {
    en,
    pl,
    fr,
    ca,
    eu,
    'en-US': enUS,
    'pt-BR': ptBR,
}
